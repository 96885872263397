$theme: core;
@use "design-system" as ds;

.searchError {
  padding: ds.spacing(huge) ds.spacing();
  @include ds.text-color(primary, $theme: $theme);
  text-align: center;
}

.searchErrorTitle {
  @include ds.typography(h2, $theme: $theme);
}
.searchErrorText {
  @include ds.stack(base);
  @include ds.typography(body, $theme: $theme);
}
