$theme: core;
@use "design-system" as ds;

$theme: core !default;
$teaser-spacing: ds.spacing();
$small-teaser-width: 64px;

.topicTeaser {
  display: block;
}

.topicTeaserContent {
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include ds.pad-lr();

  &:hover,
  &:visited:hover {
    @include ds.text-color(primary--prominent, $theme: $theme);
  }
}

.topicTeaserContentWrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.topicTeaserImage {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: $small-teaser-width;

  img {
    border-radius: 100vi;
    width: 100%;
    height: auto;
    display: block;
  }

  @media (min-width: ds.$screen-size--small) {
    width: round($small-teaser-width * ds.$desktop-upscaling-factor);
  }
}

.topicTeaserTitle {
  margin: 0;
  @include ds.typography(h2, $theme);
  @include ds.stack(none);
  @include ds.text-color(primary, $theme: $theme);
  @include ds.pad-lr();
  flex-grow: 0;
  text-wrap: balance;

  &:hover,
  &:visited:hover {
    @include ds.text-color(primary--prominent, $theme: $theme);
  }
}
