$theme: core;
@use "design-system" as ds;

.newsMixForm {
  display: flex;
  gap: ds.spacing();
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  @include ds.card($theme: $theme, $collapsing: true);
  padding: ds.spacing(regular-increased) ds.spacing();

  margin: 0;
  @media (min-width: ds.$screen-size--medium) {
    margin: 0 ds.spacing(medium);
  }
}

.submitButtonContainer {
  margin-top: ds.spacing(medium-increased);
}

.sliderDefinitionsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include ds.typography(h4, $theme);
}

.slidersContainer {
  width: 100%;
  padding: ds.spacing(regular) ds.spacing() 0 ds.spacing();
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.adjustDefinition {
  padding-right: ds.spacing(medium);
}
