$theme: core;
@use "design-system" as ds;

.navigation {
  display: flex;
  align-self: flex-end;
  width: 100%;
}

.menuButtons {
  display: flex;
  gap: ds.spacing("base");
  position: absolute;
  top: ds.spacing();
  right: ds.spacing();
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: ds.$screen-size--small) {
    right: ds.spacing(base);
  }
}

.membershipButton {
  display: none;
  @media (min-width: ds.$screen-size--small) {
    display: unset;
  }

  // the become member button has specific padding
  & a {
    padding: 11px 12px;
  }
}

.openMenu {
  overflow: hidden;
}
