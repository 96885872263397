$theme: core;
@use "design-system" as ds;

.section {
  display: flex;
  flex-direction: column;
  gap: ds.spacing();
}

.header {
  @include ds.typography(h2, $theme, $theme);
}

.link {
  @include ds.text-color(primary, $theme: $theme);
  text-decoration: underline;
}

.creativeIdContainer {
  @include ds.border("divider--subtle", $a: 1px, $s: dotted, $theme: $theme);
  border-radius: ds.spacing("base");
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.creativeId {
  user-select: all;
}
