$theme: core;
@use "design-system" as ds;

.headerContainer {
  @include ds.background(primary, $theme: $theme);
  scrollbar-gutter: stable; // pad for scrollbar on element below in order to align
  overflow: hidden;
  align-items: flex-start;
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;

  @media (min-width: ds.$screen-size--small) {
    height: ds.$header-height--largescreen;
  }

  box-sizing: border-box; // As to have borders inside the box height
  @include ds.border(divider--subtle, $t: 1px, $b: 1px, $theme: $theme);
}

.headerContent {
  position: relative;
  @include ds.pad-lr();
  height: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  gap: 0 ds.spacing(regular-increased);
  padding-top: ds.spacing(regular-increased);

  @media (min-width: ds.$screen-size--small) and (max-width: ds.$screen-size--medium) {
    margin: 0 auto;
    flex-wrap: nowrap;
    flex-direction: row;
    max-width: 688px;
    padding-top: 0;
  }

  @media (min-width: ds.$screen-size--medium) {
    margin: 0 auto;
    flex-wrap: nowrap;
    flex-direction: row;
    max-width: 970px;
    padding-top: 0;
  }
}

.headerActionsContainer {
  flex-shrink: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: right;
  gap: ds.spacing(base);
  margin-top: ds.spacing(regular-increased);

  @media (min-width: ds.$screen-size--small) {
    order: 3;
    flex-grow: 1;
    height: 100%;
    margin-top: 0;
  }
}
