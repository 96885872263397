$theme: core;
@use "design-system" as ds;

.sliderContainer {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.slider {
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  background: grey;
  border-radius: 12px;
  background: transparent;
  padding: 8px 0;
  background-repeat: no-repeat;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 25px;
    background: transparent;
    border: 0;
    border-radius: 50%;
    cursor: pointer;

    //For hiding on ios safari
    outline: none;
    border: none;
    box-shadow: none;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 25px;
    background: transparent;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
  }
}

.selectedRange {
  position: absolute;
  left: 0;
  top: 8px;
  background: ds.color("ui", brand, $theme: $theme);
  width: 100%;
  height: 2px;
  border-radius: 12px;
  pointer-events: none;
}

.unSelectedRange {
  position: absolute;
  right: 0;
  top: 8px;
  background: ds.color(ui, divider, $theme: $theme);
  width: 100%;
  height: 2px;
  border-radius: 12px;
  pointer-events: none;
}

.handle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: ds.color(ui, brand, $theme: $theme);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: none;
  transition: left 0.15s ease; // Add transition for left position
}

.dot {
  position: absolute;
  top: 50%;
  width: 6px;
  height: 6px;
  background: ds.color(ui, divider, $theme: $theme);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  &.selectedDot {
    background: ds.color(ui, brand, $theme: $theme);
  }
}
